import React, { useState, useEffect } from "react"
import LayoutES from '../../../../components/layouts-es'
import Banner from '../../../../components/common/Banner'
import { Link } from 'gatsby'
import SEO from '../../../../components/common/SEO'
import CountryPricing from '../../../../components/pricing/CountryPricing'
import Walkthrough from '../../../../images/svgs/Walkthrough'

function IndexES() {

    const title = () => (
        <div>
            <span>¿Qué es</span>
            <br />Workhub?
        </div>
    )
    const bannerProps = {
        title: title,
        subtitle: "Nosotros",
        bannerImage: "/what-is-workhub/banner4.jpg",
        buttonLeft: {
            text: "Características",
            link: "/features/",
        },
        buttonRight: {
            text: "Precios",
            link: "/pricing/",
        },
    }

    const seo = {
        title: "Workhub | What is Workhub? | Free EHS Management Software",
        description:
            "Workhub offers an all-in-one EHS Management Software with over 60+ training courses and tools to manage certificate tracking, policies, workplace screening, inspections, incident reporting, safety meetings and plenty more.",
    }


    return (
        <LayoutES>
            <SEO {...seo} />
            <Banner {...bannerProps} />

            <main className="container whatis-contain">

                {/* HOW DO WE HELP? */}

                <section className="black-swan align-child">

                    <div className="whatis-img">

                        <img src="/what-is-workhub/workers.png" alt="Workers Icon" />

                    </div>


                    <div className='whatis-text' style={{ marginLeft: '4rem' }}>

                        <p>Workhub es una solución para el programa de seguridad y salud en el trabajo que ofrece mas de 25 componentes que le ayudaran a facilitar los procesos de 
                        cumplimiento de su empresa. Ayudando a organizar todo en un solo lugar, con fácil acceso y monitoreo, permitiéndole a usted y sus trabajadores registrar
                        cada detalle y luego poder descargar esos registros por medio de reportes personalizables.
                        </p>

                    </div>

                </section>



                </main>

                <Walkthrough/>

                <main className="container whatis-contain what-is-bottom">

                {/* WE TAKE PRIDE */}

                <section className='whatis-section'>

                    <h4>Estamos muy orgullosos de lo que hacemos, trabajamos fuerte para proveer pequeñas o medianas empresas con una solución <strong>comprensiva</strong> para la administración y cumplimiento en el lugar de trabajo que es: </h4>


                    <div className='whatis-grid'>

                        <img src="/what-is-workhub/clock.png" alt="Clock Icon" />

                        <div className="whatis-child">
                            <p>
                                <strong>Fácil y rápida de usar</strong> (mejorando empresas en cuestion de horas) con</p>
                        </div>

                        <img src="/what-is-workhub/support.png" alt="Support Icon" />

                        <div className="whatis-child">
                            <p>
                                <strong>Asistencia de implementación gratuita</strong> (en caso de que necesite) y</p>
                        </div>

                        <img src="/what-is-workhub/cost.png" alt="Hand with coins Icon" />

                        <div className="whatis-child">
                            <p>
                                <strong>Un increíble bajo <Link to="/pricing/">precio</Link></strong> - <CountryPricing/>
                                {/* - just $3/user per month; plus */}
                                </p></div>

                        <img src="/what-is-workhub/contract.png" alt="Contract Icon" />

                        <div className="whatis-child">
                            <p>
                                <strong>¡Sin contratos</strong> - cancele en cualquier momento, aunque probablemente no lo haga!</p>
                        </div>


                    </div>
                
                </section>
            
            </main>
        </LayoutES>
    )
}

export default IndexES

