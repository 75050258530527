import React, { useState, useEffect } from "react"
import store from '../../../store';
import IndexES from "./es"
import IndexEng from "./eng"


function Index() {

    const [preferredLanguage, setPreferredLanguage] = useState(
        store.getState().preferredLanguage ?? "eng"
      )

      /**
   * Whenever there is a change in the language selected from the Dropdown component, there is a dispatch of the current selected language
   * when that changes this callback function runs
   * and to stop listening, invoke the function returned by the subscribe
   */

    const unsubscribe = store.subscribe(() => {
        setPreferredLanguage(store.getState().preferredLanguage);
        if(preferredLanguage !== store.getState().preferredLanguage){
            unsubscribe();
          }
    })


    /**
     * whenever the component is dismounted then stop listening to the changes in the preferred language states
     */
    useEffect(() => {
        return () => { unsubscribe() }
    }, []);

    if(store.getState().preferredLanguage == 'esp'){
        return (
            <IndexES />
        )
    }else{
        return (
            <IndexEng />
        )
    }
        
}

export default Index

